<template>
    <div class="app-container">
        <a-table 
          :data-source="templateList" 
          :columns="columns" 
          :pagination="false" 
          class="table-box"
        >
            <template #operation="{ record }" class="right">
<!--                <a-space>
                    <span class="operationLink underline" @click="updateClick(record)" v-auth="['system:cgChinaCvTemplate:upload']">更新</span>
                    <span class="operationLink underline" @click="downloadClick(record)" v-auth="['system:cgChinaCvTemplate:download']">下载</span>
                </a-space>-->
                <ActionBtn v-bind="record" :btnList="btnList" @UploadOutlined="updateClick(record)" @DownloadOutlined="downloadClick(record)" />
            </template>
        </a-table>
        
        <import-modal 
          v-model:visible="modalVisible" 
          apiFuncName="postUploadFile" 
          :uploadParams="{relationId:'',fileType:'4'}"
          @closeModal="handleCloseModal"
          @resetTable="handleResetTable"
          accept=".7z, .zip, .rar"
          :isCompressionType="true"
        />
    </div>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import { useGlobalPropertyHook } from "@/hooks/common";
import { downloadFile } from "@/utils/common";
import ImportModal from "@/components/ImportModal";
import ActionBtn from '@/components/ActionBtn'

const btnList = [
  {
    name: '上传',
    icon: 'UploadOutlined',
    auth: ['system:cgChinaCvTemplate:upload']
  },
  {
    name: '下载',
    icon: 'DownloadOutlined',
    auth: ['system:cgChinaCvTemplate:download']
  },
]
export default defineComponent({
    name: "resumeTemplateUpdated",
    components: { ImportModal, ActionBtn },

    setup() {
        const { $api } = useGlobalPropertyHook();
        const templateList = ref([]);
        const columns = [
            {
                title: "",
                dataIndex: "",
                width: "2%",
            },
            {
                title: "简历模板",
                dataIndex: "fileName",
            },
            {
                title: "操作",
                dataIndex: "operation",
                slots: { customRender: "operation" },
                width: 100,
                fixed: "right",
            },
        ];

        //查询简历模板
        const getTemplateList = () => {
            $api.getResumeTemplate().then((res) => {
                templateList.value = res.data ? [res.data] : [];
            })
        }
        //下载简历模板
        const downloadClick = (record) => {
            $api.getDownloadFiles(record).then((res) => {
                downloadFile(res.file, record.fileName);
            });
        }
        //更新简历模板
        const modalVisible = ref(false);
        const updateClick = () => {
            modalVisible.value = true;
        };
        const handleCloseModal = () => {
            modalVisible.value = false;
        };
        const handleResetTable = () => {
            getTemplateList();
        };

        onMounted(() => {
            getTemplateList();
        })

        return {
            templateList,
            columns,
            getTemplateList,
            downloadClick,
            updateClick,
            modalVisible,
            handleCloseModal,
            handleResetTable,
            btnList
        };
    },
});
</script>

<style lang="less" scoped>
@import "~@/style/common.less";
.app-container {
    height: 100%;
    padding: 20px;
    background: #ffffff;

    .table-box {
        margin: 10px 10px 0 10px;
    }
}
</style>